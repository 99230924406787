<template>
  <Component v-bind:is="displayTerminalComponent()" />
</template>

<script>
import AllFleets from "./AllFleet.vue";
import SingleFleet from "./SingleFleet.vue";
import { mapGetters } from "vuex";
export default {
  name: "TerminalManagerFleets",
  computed: {
    ...mapGetters("terminalManagerFleetComponent", ["currentComponent"]),
  },
  data() {
    return {};
  },
  methods: {
    displayTerminalComponent() {
      let component = this.currentComponent;
      if (component === "allFleets") {
        return AllFleets;
      } else if (component === "singleFleet") {
        return SingleFleet;
      }
    },
  },
  created() {
    let component = sessionStorage.getItem("terminalManagerFleetComponent");

    if (!component) {
      sessionStorage.setItem("terminalManagerFleetComponent", "allFleets");
      this.$store.dispatch(
        "terminalManagerFleetComponent/setCurrentComponent",
        "allFleets"
      );
      this.displayTerminalComponent();
    } else {
      this.$store.dispatch(
        "terminalManagerFleetComponent/setCurrentComponent",
        component
      );
      this.displayTerminalComponent();
    }
  },
};
</script>

<style lang="scss" scoped></style>
