<template>
  <v-col sm="12">
    <loader :color="companyColor" v-if="loading" />
    <v-col sm="12" class="main-body" v-else>
      <v-col sm="12" class="tw-flex tw-justify-between tw-items-center">
        <button
            class="tw-flex tw-items-center overview-btn tw-mt-14 lg:tw-mt-6"
            @click="backToAllFleet">
          <icons name="arrow-back" :color="companyColor" />
          <span class="tw-ml-4" :style="{ color: companyColor }">All Fleets</span>
        </button>
      </v-col>
      <v-col sm="12" class="tw-bg-white tw-mt-7 tw-rounded-xl tw-py-8 lg:tw-px-16">
        <v-col sm="12" >
          <img :src="fleetData.vehicleImage" alt="terminal-img" class="fleet" />
        </v-col>
        <v-row class="tw-mt-6 lg:tw-px-12 tw-justify-between tw-flex-col md:tw-flex-row">
          <v-col sm="5">
            <div class="tw-flex tw-flex-row tw-justify-between tw-items-center">
              <v-col sm="6" class="label-key tw-m-0">Brand Name</v-col>
              <v-col sm="6" class="label-value tw-m-0">{{fleetData.vehicleBrand}}</v-col>
            </div>
          </v-col>
          <v-col sm="5">
            <div class="tw-flex tw-flex-row tw-justify-between tw-items-center">
              <v-col sm="6" class="label-key tw-m-0">Terminal</v-col>
              <v-col sm="6" class="label-value tw-m-0">{{fleetData.terminalName}}</v-col>
            </div>
          </v-col>
          <v-col sm="5">
            <div class="tw-flex tw-flex-row tw-justify-between tw-items-center">
              <v-col sm="6" class="label-key tw-m-0">Tag Number</v-col>
              <v-col sm="6" class="label-value tw-m-0">{{fleetData.vehicleTagNumber}}</v-col>
            </div>
          </v-col>
          <v-col sm="5">
            <div class="tw-flex tw-flex-row tw-justify-between tw-items-center">
              <v-col sm="6" class="label-key tw-m-0">Vehicle Number</v-col>
              <v-col sm="6" class="label-value tw-m-0">{{fleetData.vehicleRegNumber}}</v-col>
            </div>
          </v-col>

          <v-col sm="5">
            <div class="tw-flex tw-flex-row tw-justify-between tw-items-center">
              <v-col sm="6" class="label-key tw-m-0">Class</v-col>
              <v-col sm="6" class="label-value tw-m-0">{{fleetData.vehicleClass}}</v-col>
            </div>
          </v-col>

          <v-col sm="5">
            <div class="tw-flex tw-flex-row tw-justify-between tw-items-center">
              <v-col sm="6" class="label-key tw-m-0">Capacity</v-col>
              <v-col sm="6" class="label-value tw-m-0">{{fleetData.vehicleCapacity}}</v-col>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-col>
  </v-col>
</template>

<script>
import Icons from "../../reuseables/Icons";
import Loader from "../../reuseables/Loader";
import {getFleetById} from "../../../services/api/APIService";
export default {
  name: "TerminalManagerSingleFleet",
  components: {Loader, Icons},
  data() {
    return {
      companyData: {},
      fleetData: {},
      companyColor: "#004AAD",
      loading: false,
      fleetId: "",
    };
  },
  methods: {
    backToAllFleet() {
      this.$store.dispatch(
        "terminalManagerFleetComponent/setCurrentComponent",
        "allFleets"
      );
      sessionStorage.setItem("terminalManagerFleetComponent", "allFleets");
    },
    async getFleetById() {
      let fleetId = sessionStorage.getItem('fleetId')
      if (fleetId){
        let data = {}
        data.fleetId = fleetId
        data.transportCompanyId = this.companyData.id
        await getFleetById(data).then(res =>{
          this.fleetData = res.data
          this.loading = false
        }).catch((err) => {
          console.log(err.response);
          this.$displaySnackbar({
            message: err.response.data.details[0],
            success: false,
          });
          this.loading = false
        });
      }
    },
  },
  async created() {
    this.loading = true;
    let companyData = JSON.parse(sessionStorage.getItem("companyData"));
    this.companyData = companyData;
    if (companyData) {
      this.companyColor = companyData.companyColor;
    }

    await this.getFleetById();
  },
};
</script>

<style lang="scss" scoped>
.label-key {
  font-family: "Poppins",sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.label-value {
  font-family: "Inter",sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 19px;
  letter-spacing: 0;
  text-align: left;
}
.terminal-img-container {
  width: 100%;
  height: 255px;
  img {
    width: 100%;
    height: 100%;
    border-radius: 4px;
  }
}
.overview-btn {
  border: none !important;
  font-family: "Inter",sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  color: rgba(0, 74, 173, 1);
}

.view-link {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 17px;
  letter-spacing: 0em;
  color: rgba(0, 74, 173, 1);
}

.overview-btn {
  border: none !important;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  color: rgba(0, 74, 173, 1);
}

.card-div {
  img {
    width: 88px;
    height: 88px;
    border-radius: 50%;
  }
  .header {
    font-family: "Inter",sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 8px;
  }

  .header-id {
    font-family: "Inter",sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 0;
  }
}

.img-div {
  img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
  }
  .header {
    font-family: "Inter",sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }

  .header-id {
    font-family: "Inter",sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
  }
}

.table-container-div {
  box-shadow: 0px 4px 40px 0px #4f4f4f05;
  border-radius: 10px;
  .table-container {
    width: 100%;
    .table-container-header {
      font-family: "Inter", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: left;
      color: #004aad;
    }
  }
}

.fleet{
  height: 200px;
  width: auto;
}
</style>
