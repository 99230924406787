<template>
  <v-col sm="12">
    <loader :color="companyColor" :loading="loading" v-if="loading"/>
    <div v-else
        class="tw-flex tw-w-full tw-flex-col lg:tw-flex-row lg:tw-justify-between
         lg:tw-py-5  tw-mt-20  lg:tw-mt-5 table-container-div tw-p-5 lg:tw-pl-8"
        style="background: white;">
      <div class="table-container lg:tw-pr-8">
        <div class="tw-flex tw-flex-col lg:tw-flex-row  lg:tw-justify-between lg:tw-items-center">
          <p class="table-header" :style="{ color: companyColor }">
            All Fleets
          </p>
          <div class="tw-flex tw-flex-row lg:tw-justify-center tw-justify-end tw-items-center">
            <div
                class="mr-2"
                style="border-radius: 10px; border: 1px solid rgba(79, 79, 79, 0.08)"
            >
              <v-text-field
                  placeholder="Enter vehicle number"
                  class="p-0"
                  solo
                  flat
                  hide-details
                  v-model="search"
              >
                <template #prepend>
                  <v-icon :color="companyColor" class="ml-5">
                    mdi-magnify
                  </v-icon>
                </template>
                <template #append>
                  <v-icon :color="companyColor">
                    mdi-filter-variant
                  </v-icon>
                </template>
              </v-text-field>
            </div>
          </div>
        </div>
        <v-data-table
            :headers="headers"
            v-model="selectedRow"
            :mobile-breakpoint="0"
            :search="search"
            item-key="id"
            :single-select="singleSelect"
            :sort-by="[]"
            height="available"
            :hide-default-footer="fleets.length <= 10"
            :items="fleets"
            :no-data-text="not_found"
            class="mt-4 mx-1 table"
            :checkbox-color="companyColor"
            @click:row="viewSingle"
        >
          <template #header.sno>
            <span class="status">S/N</span>
          </template>
          <template v-slot:[`item.sno`]="{ item }">
            <span class="td-style">{{ item.sno }}</span>
          </template>
          <template #header.vehicleImage>
            <span class="status">Image</span>
          </template>
          <template v-slot:[`item.vehicleImage`]="{ item }">
            <img
                :src="item.vehicleImage"
                alt="vehicle"
                v-if="item.vehicleImage"
            />
            <div
                class="tw-flex tw-justify-center tw-items-center no-image"
                v-else
                :style="{ backgroundColor: companyColor }"
            >
              <p class="tw-text-center tw-p-0 tw-m-0 tw-mb-o">
                {{ item.vehicleBrand[0] }}
              </p>
            </div>
          </template>
          <template #header.vehicleType>
            <span class="status">Type</span>
          </template>
          <template v-slot:[`item.vehicleType`]="{ item }">
            <span class="td-style">{{ item.vehicleType }}</span>
          </template>
          <template #header.vehicleClass>
            <span class="status">Class</span>
          </template>
          <template v-slot:[`item.vehicleClass`]="{ item }">
            <span class="td-style">{{ item.vehicleClass }}</span>
          </template>
          <template #header.vehicleRegNumber>
            <span class="status">Vehicle Number</span>
          </template>
          <template v-slot:[`item.vehicleRegNumber`]="{ item }">
            <span class="td-style">{{ item.vehicleRegNumber }}</span>
          </template>
          <template #header.vehicleTagNumber>
            <span class="status">TAG NUMBER</span>
          </template>
          <template v-slot:[`item.vehicleTagNumber`]="{ item }">
            <span class="td-style">{{ item.vehicleTagNumber }}</span>
          </template>
          <template #header.vehicleBrand>
            <span class="status">Brand Name</span>
          </template>
          <template v-slot:[`item.vehicleBrand`]="{ item }">
            <span class="td-style">{{ item.vehicleBrand }}</span>
          </template>
          <template #header.vehicleCapacity>
            <span class="status">Capacity</span>
          </template>
          <template v-slot:[`item.vehicleCapacity`]="{ item }">
            <span class="td-style">{{ item.vehicleCapacity }}</span>
          </template>
          <template #header.terminalName>
            <span class="status">Terminal</span>
          </template>
          <template v-slot:[`item.terminalName`]="{ item }">
            <span class="td-style">{{ item.terminalName }}</span>
          </template>

            <template #header.status>
                <span class="status">Status</span>
            </template>
            <template v-slot:[`item.status`]="{ item }">
                <span class="td-style">{{ item.status }}</span>
            </template>

          <template v-slot:[`item.actions`] = "{item}">
            <v-menu bottom offset-y left>
              <template v-slot:activator="{ on, attrs }">
                <td class="d-flex justify-center">
                  <v-btn v-bind="attrs" v-on="on" icon>
                    <v-icon :color="companyColor">mdi-dots-horizontal</v-icon>
                  </v-btn>
                </td>
              </template>
              <v-list>
                  <v-list-item @click="handleTransferModal(item)">
                      <v-list-item-title
                              style="color: black"
                              class="tw-flex-row tw-flex"
                      >
                          <v-icon
                                  class="tw-mr-2"
                          >mdi-transfer</v-icon>
                          Transfer
                      </v-list-item-title>
                  </v-list-item>
                <v-list-item @click="viewSingle(item)">
                  <v-list-item-title
                      style="color: black"
                      class="tw-flex-row tw-flex tw-items-center menu-text">
                      View
                    <v-icon :color="companyColor" >mdi-arrow-right</v-icon>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </div>
        <modal section="transfer-vehicle" :dialog="showTransferModal"
               :color="companyColor" @close="closeTransferModal"
               :fleetToTransfer="fleetToTransfer" @transferFleet="transferFleet" :loading="modalLoading"/>
    </div>
  </v-col>
</template>

<script>
import {getAllCompanyFleets, transferFleet} from "@/services/api/APIService";
import Loader from "../../reuseables/Loader";
import dayjs from "dayjs";
import Modal from "@/components/reuseables/Modal.vue";

export default {
  name: "TerminalManagerAllFleets",
  components: {Modal, Loader},
  data() {
    return {
      noImage: false,
      companyData: {},
      fleets: [],
      companyColor: "#004AAD",
      loading: false,
      headers: [
        {text: "",value: "sno"},
        { text: "", value: "vehicleImage" },
        { text: "", value: "vehicleType" },
        { text: "", value: "vehicleClass" },
        { text: "", value: "vehicleRegNumber" },
        { text: "", value: "vehicleTagNumber" },
        { text: "", value: "vehicleBrand" },
        { text: "", value: "vehicleCapacity" },
        { text: "", value: "terminalName" },
          { text: "", value: "status" },
        { text: "", value: "actions", align: "end" },
      ],
      not_found: "Fleet will display here",
      selectedRow: [],
      singleSelect: false,
      search: "",
      showTransferModal : false,
      fleetToTransfer : {},
      modalLoading : false
    };
  },
  methods: {
    viewSingle(fleet) {
      this.$store.dispatch(
        "terminalManagerFleetComponent/setCurrentComponent",
        "singleFleet"
      );
      sessionStorage.setItem("terminalManagerFleetComponent", "singleFleet");
      sessionStorage.setItem("fleetId",fleet.id)
    },
    async getAllFleets() {
      try {
        const response = await getAllCompanyFleets(this.companyData.id);
        response.data = response.data.filter(fleet => !fleet.clone)
        this.fleets = response.data;

        this.fleets.sort(function (a, b){
          return  dayjs(b.createdAt).toDate() - dayjs(a.createdAt).toDate()
        })
        this.fleets.forEach((fleet,index) => fleet.sno = index + 1)
        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.log(err.response);
        this.$displaySnackbar({
          message: err.response.data.details[0],
          success: false,
        });
      }
    },
      handleTransferModal(fleet){
          this.showTransferModal = !this.showTransferModal
          this.fleetToTransfer = fleet
      },
      async transferFleet(receivingTerminal){
          console.log(receivingTerminal, "receiving terminal",this.fleetToTransfer)
          if (receivingTerminal.id === this.fleetToTransfer.newDepartureTerminalId){
              this.$displaySnackbar({
                  message: "Selected Fleet currently in this terminal",
                  success: false,
              });
          }
          else {
              this.modalLoading = true
              let data = {}
              data.transportCompanyId= receivingTerminal.transportCompanyId
              data.fleetId= this.fleetToTransfer.id
              data.receivingTerminalId = receivingTerminal.id
              await transferFleet(data).then(res =>{
                  this.$displaySnackbar({
                      message: res.data.detail,
                      success: true,});
                  this.modalLoading = false
                  this.handleTransferModal()
                  this.getAllFleets()
              }).catch(err =>{
                  console.log(err.response)
                  this.$displaySnackbar({
                      message: err.response.data.message,
                      success: false,
                  });
              }).finally(() => this.modalLoading = false)
          }
      },
      closeTransferModal(){
          this.showTransferModal = false
      },
  },
  async created() {
    this.loading = true;
    let companyData = JSON.parse(sessionStorage.getItem("companyData"));
    this.companyData = companyData;
    if (companyData) {
      this.companyColor = companyData.companyColor;
    }
    await this.getAllFleets();
  },
};
</script>

<style lang="scss" scoped>
.table-header {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
}

.table-container-div {
  box-shadow: 0px 4px 40px 0px #4f4f4f05;
  border-radius: 10px;
  .table-container {
    width: 100%;
    .table-container-header {
      font-family: "Inter";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: left;
      color: #004aad;
    }
  }
  img {
    width: 32px;
    height: 32px;
    border-radius: 4px;
    object-fit: contain;
  }
}

.no-image {
  width: 32px;
  height: 32px;
  border-radius: 50%;

  p {
    font-family: "Inter", serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0;
    margin-bottom: 0 !important;
    color: rgba(255, 255, 255, 1);
  }
}

img {
  width: 32px;
  height: 32px;
  border-radius: 5px;
}


::v-deep .v-text-field--rounded > .v-input__control > .v-input__slot {
  padding: 0;
}

.v-data-table__wrapper > table {
  margin-top: 55px !important;
}

.theme--light.v-data-table
> .v-data-table__wrapper
> table
> thead
> tr
> th:first-child,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td:first-child {
  padding-left: 16px;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.status {
  font-family: "Poppins",sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0;
  text-align: left;
  color: #4f4f4f;
  padding: 0 0 0 5px;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.td-style {
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 15px;
  letter-spacing: 0;
  text-align: left;
  padding: 0 0 0 5px;
}

.menu-text{
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 15px;
  letter-spacing: 0;

}
</style>
