var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{attrs:{"sm":"12"}},[(_vm.loading)?_c('loader',{attrs:{"color":_vm.companyColor,"loading":_vm.loading}}):_c('div',{staticClass:"tw-flex tw-w-full tw-flex-col lg:tw-flex-row lg:tw-justify-between\n       lg:tw-py-5  tw-mt-20  lg:tw-mt-5 table-container-div tw-p-5 lg:tw-pl-8",staticStyle:{"background":"white"}},[_c('div',{staticClass:"table-container lg:tw-pr-8"},[_c('div',{staticClass:"tw-flex tw-flex-col lg:tw-flex-row  lg:tw-justify-between lg:tw-items-center"},[_c('p',{staticClass:"table-header",style:({ color: _vm.companyColor })},[_vm._v(" All Fleets ")]),_c('div',{staticClass:"tw-flex tw-flex-row lg:tw-justify-center tw-justify-end tw-items-center"},[_c('div',{staticClass:"mr-2",staticStyle:{"border-radius":"10px","border":"1px solid rgba(79, 79, 79, 0.08)"}},[_c('v-text-field',{staticClass:"p-0",attrs:{"placeholder":"Enter vehicle number","solo":"","flat":"","hide-details":""},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-icon',{staticClass:"ml-5",attrs:{"color":_vm.companyColor}},[_vm._v(" mdi-magnify ")])]},proxy:true},{key:"append",fn:function(){return [_c('v-icon',{attrs:{"color":_vm.companyColor}},[_vm._v(" mdi-filter-variant ")])]},proxy:true}]),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)])]),_c('v-data-table',{staticClass:"mt-4 mx-1 table",attrs:{"headers":_vm.headers,"mobile-breakpoint":0,"search":_vm.search,"item-key":"id","single-select":_vm.singleSelect,"sort-by":[],"height":"available","hide-default-footer":_vm.fleets.length <= 10,"items":_vm.fleets,"no-data-text":_vm.not_found,"checkbox-color":_vm.companyColor},on:{"click:row":_vm.viewSingle},scopedSlots:_vm._u([{key:"header.sno",fn:function(){return [_c('span',{staticClass:"status"},[_vm._v("S/N")])]},proxy:true},{key:"item.sno",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"td-style"},[_vm._v(_vm._s(item.sno))])]}},{key:"header.vehicleImage",fn:function(){return [_c('span',{staticClass:"status"},[_vm._v("Image")])]},proxy:true},{key:"item.vehicleImage",fn:function(ref){
var item = ref.item;
return [(item.vehicleImage)?_c('img',{attrs:{"src":item.vehicleImage,"alt":"vehicle"}}):_c('div',{staticClass:"tw-flex tw-justify-center tw-items-center no-image",style:({ backgroundColor: _vm.companyColor })},[_c('p',{staticClass:"tw-text-center tw-p-0 tw-m-0 tw-mb-o"},[_vm._v(" "+_vm._s(item.vehicleBrand[0])+" ")])])]}},{key:"header.vehicleType",fn:function(){return [_c('span',{staticClass:"status"},[_vm._v("Type")])]},proxy:true},{key:"item.vehicleType",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"td-style"},[_vm._v(_vm._s(item.vehicleType))])]}},{key:"header.vehicleClass",fn:function(){return [_c('span',{staticClass:"status"},[_vm._v("Class")])]},proxy:true},{key:"item.vehicleClass",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"td-style"},[_vm._v(_vm._s(item.vehicleClass))])]}},{key:"header.vehicleRegNumber",fn:function(){return [_c('span',{staticClass:"status"},[_vm._v("Vehicle Number")])]},proxy:true},{key:"item.vehicleRegNumber",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"td-style"},[_vm._v(_vm._s(item.vehicleRegNumber))])]}},{key:"header.vehicleTagNumber",fn:function(){return [_c('span',{staticClass:"status"},[_vm._v("TAG NUMBER")])]},proxy:true},{key:"item.vehicleTagNumber",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"td-style"},[_vm._v(_vm._s(item.vehicleTagNumber))])]}},{key:"header.vehicleBrand",fn:function(){return [_c('span',{staticClass:"status"},[_vm._v("Brand Name")])]},proxy:true},{key:"item.vehicleBrand",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"td-style"},[_vm._v(_vm._s(item.vehicleBrand))])]}},{key:"header.vehicleCapacity",fn:function(){return [_c('span',{staticClass:"status"},[_vm._v("Capacity")])]},proxy:true},{key:"item.vehicleCapacity",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"td-style"},[_vm._v(_vm._s(item.vehicleCapacity))])]}},{key:"header.terminalName",fn:function(){return [_c('span',{staticClass:"status"},[_vm._v("Terminal")])]},proxy:true},{key:"item.terminalName",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"td-style"},[_vm._v(_vm._s(item.terminalName))])]}},{key:"header.status",fn:function(){return [_c('span',{staticClass:"status"},[_vm._v("Status")])]},proxy:true},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"td-style"},[_vm._v(_vm._s(item.status))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('td',{staticClass:"d-flex justify-center"},[_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.companyColor}},[_vm._v("mdi-dots-horizontal")])],1)],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.handleTransferModal(item)}}},[_c('v-list-item-title',{staticClass:"tw-flex-row tw-flex",staticStyle:{"color":"black"}},[_c('v-icon',{staticClass:"tw-mr-2"},[_vm._v("mdi-transfer")]),_vm._v(" Transfer ")],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.viewSingle(item)}}},[_c('v-list-item-title',{staticClass:"tw-flex-row tw-flex tw-items-center menu-text",staticStyle:{"color":"black"}},[_vm._v(" View "),_c('v-icon',{attrs:{"color":_vm.companyColor}},[_vm._v("mdi-arrow-right")])],1)],1)],1)],1)]}}],null,true),model:{value:(_vm.selectedRow),callback:function ($$v) {_vm.selectedRow=$$v},expression:"selectedRow"}})],1),_c('modal',{attrs:{"section":"transfer-vehicle","dialog":_vm.showTransferModal,"color":_vm.companyColor,"fleetToTransfer":_vm.fleetToTransfer,"loading":_vm.modalLoading},on:{"close":_vm.closeTransferModal,"transferFleet":_vm.transferFleet}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }